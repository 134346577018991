<template>
  <div class="pageview">
    <!-- banner切换 -->
    <div class="header">
      <div class="personal">
        <van-swipe class="homebanner"
          :autoplay="3000"
          indicator-color="white">
          <van-swipe-item><img :src="require('../../assets/partymember/banner01.png')" /></van-swipe-item>
          <van-swipe-item><img :src="require('../../assets/partymember/党建1.jpg')" /></van-swipe-item>
          <van-swipe-item><img :src="require('../../assets/partymember/dangjian.jpg')" /></van-swipe-item>

        </van-swipe>
      </div>
    </div>
    <div style="margin: 10px auto;border-radius: 5px;width: 95%;">
      <div style="border-radius: 5px;background: #ffff;">
        <van-grid class="wktype"
          :border="false">
          <van-grid-item icon="photo-o"
            @click="gotoUrlDj('/partymember/history')">
            <van-image class="iconimg"
              :src="require('../../assets/partymember/党史教育 (2).png')"></van-image>
            <span class="wktext">党史教育</span>
          </van-grid-item>
          <van-grid-item icon="photo-o"
            @click="gotoUrlDj('/partymember/consult')">
            <van-image class="iconimg"
              :src="require('../../assets/partymember/党务资讯 (2).png')"></van-image>
            <span class="wktext">党务资讯</span>
          </van-grid-item>
          <van-grid-item icon="photo-o"
            @click="gotoUrlDj('/partymember/activity')">
            <van-image class="iconimg"
              :src="require('../../assets/partymember/党建活动 (2).png')"></van-image>
            <span class="wktext">党建活动</span>
          </van-grid-item>
          <van-grid-item icon="photo-o"
            @click="gotoUrl('/partymember/integral')">
            <van-image class="iconimg"
              :src="require('../../assets/partymember/积分排行.png')"></van-image>
            <span class="wktext">积分排行</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrlDj('/partymember/wuye/wuyeList')">
            <van-image class="iconimg"
              :src="require('../../assets/partymember/物业.png')"></van-image>
            <span class="wktext">红色物业</span>
          </van-grid-item> -->
        </van-grid>
      </div>
    </div>
    <div class="djlink">
      <!-- <a href="https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB" -->
      <!-- referrerpolicy="no-referrer">  -->
      <!-- <img :src="require('../../assets/partymember/党建2.jpg')"
        width="100%"
        alt=""> -->
      <img :src="require('../../assets/partymember/党建3.png')"
        width="100%"
        alt="">
      <!-- </a> -->

    </div>

    <div class="contentmsg">
      <div class="contentA"
        style="padding-bottom:0;">
        <!-- <img :src="require('../../assets/partymember/content.png')" /> -->
        <span class="grouptitle"
          style="line-height: 20px;">通知公告</span>
        <!-- <img style="width: 90%;"
            src="https://api.qxzhqm.cn//upload/images/2022/5/c0e7b131-cf5.png" />-->
        <!-- <span class="blue">公告</span> -->
        <div @click="gotoUrlDj('/partymember/message')"
          class="newsmore">查看全部</div>
      </div>
      <div class="information">
        <div class="center"
          v-for="item in List"
          :key="item.index">
          <div @click="gotoUrlDj('/partymember/message/' + item.RIId)">
            <div class="Content">

              <div style="display: inline-block;width: 67%;"
                class="ContentB">
                <div class="centerTitle van-ellipsis--l2">{{ item.Title }}</div>
                <!-- <div class="van-multi-ellipsis--l3"
                  style="font-size: 10px;margin: 2px 0;">{{ item.Summary }}</div> -->
                <!-- <br v-if="item.Summary == ''" /> -->
                <!-- <br v-if="item.Summary == ''" /> -->

              </div>
              <div style="display: inline-block;width: 27%;"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.ThematicUrl"
                  :src="item.ThematicUrl"
                  alt="" />
              </div>
              <span class="centertime"
                style="float: left;margin-top: -5px;color:#999999;">{{ item.IssTime }}</span>
            </div>
          </div>
        </div>
        <van-empty description="暂无通知"
          v-if="!List.length" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { WeGetPartyInfoPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data () {
    return {
      List: [],
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.getPartyInfoList();
  },
  methods: {
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    gotoUrlDj (path) {
      // this.$router.push(path);
      // Toast.fail(path);
      if (window.__wxjs_environment === "miniprogram") {
        // Toast.fail(path);
        window.wx.miniProgram.navigateTo({
          url: "/pages/public/dangjian/dangjian?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getPartyInfoList () {
      WeGetPartyInfoPage({ riKind: 1, limit: 5 }).then((res) => {
        this.List = res.data.data;
      });
    },
    // 党务
    dangwu: function () {
      var url = 'https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB'
      window.localtion.href = url
      // window.open('https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB');
    },
  },
};
</script>

<style scoped>
.personal {
  width: 100%;
  align-items: center;
  text-align: center;
  /* position: absolute;
  top: 10px; */
}
.header {
  /* position: relative;
  height: 120px; */
  /* margin-bottom: 80px; */
  background-image: url('https://api.qxzhqm.cn//upload/images/2022/5/88f9f141-3d4.png');
  background-size: 100% 50%;
  background-repeat: no-repeat;
  /* background-color: #e3393c; */
}
.bannerImage {
  width: 100%;
  vertical-align: top;
  margin: auto;
  border-radius: 5px;
}
.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.djlink {
  width: 95%;
  margin: auto;
  margin-top: 1.5vh;
  border-radius: 5px;
  overflow: hidden;
  display: block;
  font-size: 0;
}
.contentA {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  padding: 1vh 1.5vh;
  box-sizing: border-box;
}
.contentA img {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
}
.contentA span {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  line-height: 30px;
}
.contentA .newsmore {
  float: right;
  color: #777;
  font-size: 14px;
  line-height: 30px;
}
.contentA .blue {
  color: #477fff;
}
.pageview {
  background: #f6f6f6;
  min-height: 100%;
  /* padding: 0 1vh; */
  box-sizing: border-box;
}
.homebanner img {
  width: 95%;
  height: 20vh;
  /* padding-top: 5px; */
  vertical-align: top;
  margin: 5px auto 0;
  border-radius: 5px;
}
.wktype {
  padding: 0 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.contentmsg {
  background: #fff;
  width: 95%;
  margin: auto;
  margin-top: 1.5vh;
  border-radius: 5px;
}
.grouptitle {
  border-left: 4px solid rgba(237, 102, 78, 1);
  line-height: 20px;
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
  margin: 12px 5px;
  padding-left: 5px;
}
</style>