import request from '@/utils/request'
// 获取资讯信息列表-居民端
export function WeGetRealInfoPage (query) {
  return request({
    url: 'RealInfo/WeGetRealInfoPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息-居民端
export function WeGetRealInfo (query) {
  return request({
    url: 'RealInfo/WeGetRealInfo',
    method: 'get',
    params: query
  })
}
// 小程序-获取社区活动分页列表
export function WeGetActivityPage (query) {
  return request({
    url: 'Activity/WeGetActivityPage',
    method: 'get',
    params: query
  })
}
// 获取社区活动详情
export function WeGetActivityDetail (query) {
  return request({
    url: 'Activity/WeGetActivityDetail',
    method: 'get',
    params: query
  })
}
// 小程序-报名参与社区活动
export function WeEnrolActivity (data) {
  return request({
    url: 'Activity/WeEnrolActivity',
    method: 'post',
    data: data
  })
}
// 小程序获取商铺分页列表
export function WeGetShopPage (query) {
  return request({
    url: 'Shop/WeGetShopPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息列表-党员端
export function WeGetPartyInfoPage (query) {
  return request({
    url: 'PartyInfo/WeGetPartyInfoPage',
    method: 'get',
    params: query
  })
}
// 获取党员积分分页列表[党员端]
export function WeGetPMIntegralPage (query) {
  return request({
    url: 'Party/WeGetPMIntegralPage',
    method: 'get',
    params: query
  })
}
// 小程序-获取党组活动分页列表
export function WeGetMyActivityPage (query) {
  return request({
    url: 'PBActivity/WeGetMyActivityPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息-党员端
export function WeGetPartyInfo (query) {
  return request({
    url: 'PartyInfo/WeGetPartyInfo',
    method: 'get',
    params: query
  })
}
// 小程序党建大事件时间轴列表
export function WxGetDateAxisList (query) {
  return request({
    url: 'PartyInfo/WxGetDateAxisList',
    method: 'get',
    params: query
  })
}
// 居民端-获取我的商铺列表
export function WxGetMyShopList (query) {
  return request({
    url: 'Shop/WxGetMyShopList',
    method: 'get',
    params: query
  })
}

// 居民端-重新绑定微信
export function WxReBindShop (data) {
  return request({
    url: 'Shop/WxReBindShop',
    method: 'post',
    data
  })
}

// 居民端-获取我的企业信息列表
export function WxGetMyEnterpriseList (query) {
  return request({
    url: 'Enterprise/WxGetMyEnterpriseList',
    method: 'get',
    params: query
  })
}
// 居民端-获取单位从业人员列表
export function WxGetEntStaffPage (query) {
  return request({
    url: 'Staff/WxGetEntStaffPage',
    method: 'get',
    params: query
  })
}
// 居民端-保存从业人员信息
export function WxSaveStaff (data) {
  return request({
    url: 'Staff/WxSaveStaff',
    method: 'post',
    data
  })
}

// 获取从业人员详情
export function GetStaff (query) {
  return request({
    url: 'Staff/GetStaff',
    method: 'get',
    params: query
  })
}

// 删除员工信息
export function WxDeleteStaff (data) {
  return request({
    url: 'Staff/WxDeleteStaff',
    method: 'post',
    data
  })
}
// 居民端-获取我的隔离登记信息列表
export function WxGetIsolationPage (query) {
  return request({
    url: 'Isolation/WxGetIsolationPage',
    method: 'get',
    params: query
  })
}


// 居民端-我的返淇排查列表
export function WxGetReportingPage (query) {
  return request({
    url: 'Vaccine/WxGetReportingPage',
    method: 'get',
    params: query
  })
}

// 居民端-我的疫苗排查列表
export function WxGetCheckPage (query) {
  return request({
    url: 'Vaccine/WxGetCheckPage',
    method: 'get',
    params: query
  })
}

// 居民端-更新疫苗接种状态
export function WxUpdateVaccinateStatus (data) {
  return request({
    url: 'Vaccine/WxUpdateVaccinateStatus',
    method: 'post',
    data
  })
}

// 居民端-我的返淇排查列表
export function WxGetOutReportingPage (query) {
  return request({
    url: 'Vaccine/WxGetOutReportingPage',
    method: 'get',
    params: query
  })
}
// 获取我的证件列表
export function WxGetMyResidentCertList (query) {
  return request({
    url: 'ResidentCert/WxGetMyResidentCertList',
    method: 'get',
    params: query
  })
}
// 居民端-删除我的证件
export function WxDeleteMyResidentCert (data) {
  return request({
    url: 'ResidentCert/WxDeleteMyResidentCert',
    method: 'post',
    data
  })
}

// 获取政务公开
export function WeGetAppletOrganList (query) {
  return request({
    url: 'Organ/WeGetAppletOrganList',
    method: 'get',
    params: query
  })
}

// 获取政务公开详情
export function WeGetAppletOrgan (query) {
  return request({
    url: 'Organ/WeGetAppletOrgan',
    method: 'get',
    params: query
  })
}